// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'http://api.bissniss.com',
  TOKEN: 'JOBSPACE_TOKEN',
  IS_LOGGED_IN: 'IS_LOGGED_IN',
  UNAUTHORIZED: 'Unauthorized',
  firebaseConfig: {
    // apiKey: "AIzaSyCiAaG6cc6uvHQqzplsZYTr0SdHhfcsDYw",
    // authDomain: "testproject-1538578862843.firebaseapp.com",
    // databaseURL: "https://testproject-1538578862843.firebaseio.com",
    // projectId: "testproject-1538578862843",
    // storageBucket: "testproject-1538578862843.appspot.com",
    // messagingSenderId: "729690523253",
    // appId: "1:729690523253:web:a437b2dc5b9cba9a259a3c",
    // measurementId: "G-4SY4951VP9"
    apiKey: "AIzaSyDYNVWiGkCKNInChmD06S8D_HbJwDtJ_oQ",
    authDomain: "jobspace-17da9.firebaseapp.com",
    databaseURL: "https://jobspace-17da9.firebaseio.com",
    projectId: "jobspace-17da9",
    storageBucket: "jobspace-17da9.appspot.com",
    messagingSenderId: "330557579407",
    appId: "1:330557579407:web:b040b9bccbe59ba43ad0fa",
    measurementId: "G-JSTT0N33P0"
  },
  END_POINT: "http://api.bissniss.com",
  ROOT_PHOTO: "http://api.bissniss.com/images",
  ROOT_CHAT: "http://api.bissniss.com:6002/"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
